import React from 'react'


import { msalConfig, loginRequest } from '../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';

import classes from '../styles/course-material.module.sass'

import Wrapper from '../components/wrapper'

import { graphql } from 'gatsby'

import { ChapterItem } from '../components/chapter-item'
import CourseContentList from '../components/course-content-list'


export default ({data}) => 
{
    /*const data = props.pageContext.data ? props.pageContext.data : [];
    const chapters = data.map( ({node}, i) => (
       <ChapterItem type="chapter" url={node.fields.slug} keyValue={i} description={node.frontmatter.description} title={node.frontmatter.title}> </ChapterItem>
    ))*/

    const chaptersData =  data.allMarkdownRemark.edges.filter(
        ({ node }) => node.frontmatter.type === 'chapter' 
    )

    const chapters = chaptersData.map( ({node}, i) => {
        const tasks= data.allMarkdownRemark.edges.filter((entry) => entry.node.frontmatter.chapter_id === node.frontmatter.id)


        return (
        <ChapterItem type="chapter" id={node.frontmatter.id} tasks={tasks}  url={node.fields.slug} keyValue={i} description={node.frontmatter.description} title={node.frontmatter.title} index={node.frontmatter.index}> </ChapterItem>
        )})


    chapters.sort(function(a, b) {
        if (a.props.index === null || b.props.index === null)
            return true;
        
        return a.props.index - b.props.index;
    });

    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
            <div className={classes.container}>
                <h1 className={classes.pagetitle}>Kursinnehåll</h1>
            </div>

            <CourseContentList>
                {chapters}
            </CourseContentList>

            </Wrapper>
        </MsalProvider>
    )
}

export const pageQuery = graphql`
    {
        site {
            siteMetadata {
                title
            }
        }
            allMarkdownRemark {
                edges {
                    node {
                        htmlAst
                        frontmatter {
                            title
                            type
                            description    
                            index
                            id        
                            chapter_id               
                        }
                        fields {
                            slug
                            fullPath
                        }
                    }
                }
            }
        }
        `


//         allMarkdownRemark(
//             sort: { fields: [frontmatter___title], order: ASC }
//             filter: { frontmatter: { type: { eq: "chapter" } } }
//         ) {
//             edges {
//                 node {
//                     fields {
//                         slug
//                     }
//                     frontmatter {
//                         title
//                         description
//                         index
//                     }
//                 }
//             }
//         }
//     }
// `